@import '../../../../assets/styles/variables';

.tiny-text {
  margin: 0;
  font-family: $open-sans;
  font-size: 13px;
  line-height: 18px;
  color: $tuatara;

  &--bold {
    font-weight: 600;
  }
}
