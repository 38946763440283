@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.test-block {
  padding: 20px $mobile-side-margin 20px;
  background-color: $alice-blue;
  text-align: left;

  @include desktop {
    padding: 20px 20px 35px;
  }

  &__title {
    display: block;
    margin: 0;
    font-family: $open-sans-bold;
    font-size: 22px;
    font-weight: bold;
    color: $curious-blue;
  }

  &__description {
    display: block;
    margin: 25px 0 0;
    line-height: 1.4;
    font-family: $open-sans;
    font-size: 13px;
    font-weight: 600;
    color: $tuatara;
    white-space: pre-line;

    p {
      & + p {
        margin-top: 5px;
      }
    }
  }

  &__info {
    display: block;
    margin: 15px 0;
    font-family: $open-sans;
    font-size: 13px !important;
    color: $tuatara;

    .rte__paragraph {
      margin: 5px 0;
      display: block;

      .ret__strong {
        font-weight: 600;
      }
    }

    .rte__list {
      padding: 0;
      margin-bottom: 15px;

      .rte__list-item {
        list-style: none;
        padding: 0 0 0 10px;

        &::before {
          left: 0;
          top: 0.6em;
          background: $tuatara;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__doc {
    display: inline-flex;
    align-items: flex-start;
    margin: 10px 10px 0 0;

    .icon {
      width: 12px;
      height: 12px;
    }
  }

  .button {
    margin-top: 20px;
  }
}
