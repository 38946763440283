@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.case-study {
  display: flex;
  align-items: center;

  &__image {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;

    &-wrapper {
      position: relative;
      flex: 0 0 130px;
      overflow: hidden;

      &::before {
        display: block;
        width: 100%;
        padding-top: 100%;
        content: '';
      }
    }
  }

  &__content {
    padding: 20px;
    text-align: left;

    @include mobile {
      padding: 20px 15px 20px 20px;
    }
  }

  &__type {
    display: block;
    margin-bottom: 5px;
  }

  &__title {
    font-family: $open-sans;
    font-size: 14px;
    font-weight: bold;
    color: $tuatara;
    word-break: break-word;

    .link {
      margin-left: 0;
      font: inherit;
      color: inherit;
    }
  }
}
